import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";

import { IPlanningList, } from "@/api/interfaces/responses";

import { useQuery, useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";

import { columns } from "../models";
import { isUndefined } from "@/utils/helpers";
import { TPlanningKeys } from "../interfaces";
import {
  IPlanningQueryParams
} from "@/api/interfaces/requests";
import {
  ADD_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT_WITH_FILTERS,
} from "../const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
  DOWNLOAD_TRIALS_PLAN_HP_TYPES,
} from "@/const";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Filters from "@/components/Filters";
import { getFilters } from "../utils";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * Компонент, представляющий таблицу планирование ГСУ.
 * @returns {ReactElement}
 */
const PlanningTable = (): ReactElement => {
  const {
    api,
    queryStringSidebarCollector,
    swrStore,
    userStore,
  } =
    useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const query = useQuery<IPlanningQueryParams>();
  const [queryParams, setQueryParams] =
    useState<IPlanningQueryParams>(query);

  const location = useLocation();
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      key: SWR_KEYS.getPlanningTable(),
      search: queryParams?.search,
      trial_type: queryParams?.trial_type?.key,
      status: queryParams?.status?.key,
      year_min: queryParams?.year_min,
      year_max: queryParams?.year_max,
      expert_assessment: false
    },
    api.planning.getPlanning,
  );

  swrStore.addMutator(SWR_KEYS.getPlanningTable(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TPlanningKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean =
    Object.keys(queryParams).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {

    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      module_id: id,
    });
    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING,
      },
    });
    setSearchParams(str)
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  const { modalStore } = useStores();
  const handleAddTrial = () => {
    modalStore.open(VARIANT_MODAL.SELECT_TRIAL_PLAN);

  };
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadBranchPlan = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_PLANNING, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_TRIALS_PLAN_HP_TYPES.BRANCH
    });
    handleClose();
  };

  const handleDownloadRegionPlan = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_PLANNING, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_TRIALS_PLAN_HP_TYPES.REGION
    });
    handleClose();
  };

  const handleDownloadCultivarPlan = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_PLANNING, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_TRIALS_PLAN_HP_TYPES.CULTIVAR
    });
    handleClose();
  };

  const filters = getFilters(
    [query?.date_range_after, query?.date_range_before],
    query?.status,
    query?.trial_type,
  );

  const canAddPlan = userStore.isPermission(
    PERMISSIONS.planningPlan,
    PERMISSION_CRUD.add
  );

  const canDownloadDoc = userStore.isPermission(
    PERMISSIONS.planningPlanDownloadedDocuments,
    PERMISSION_CRUD.view
  );

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
        }}>
          <Filters
            filters={filters}
            defaultSearch={query.search}
          />

          {canDownloadDoc &&
            <Button
              color="blue"
              onClick={handleClick}
              variant="outlined"
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Документы
            </Button>}
        </Box>

        {canAddPlan &&
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddTrial}
          >
            Добавить план
          </Button>}
      </Box>

      <Table<IPlanningList, TPlanningKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_PLANING_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_PLANING_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_PLANING_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={handleDownloadBranchPlan}>
          Сводный план по испытаниям на ХП по филиалам
        </MenuItem>
        <MenuItem onClick={handleDownloadRegionPlan}>
          Сводный план по испытаниям на ХП по регионам
        </MenuItem>
        <MenuItem onClick={handleDownloadCultivarPlan}>
          План по культуре
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default PlanningTable;
