import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@/components/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME } from "../const";
import { IWorkType } from "@/api/interfaces/responses";

interface IProps {
  handleClose: () => void;
}

const AddWorkForm: React.FC<IProps> = ({
  handleClose,
}) => {
  const { api, swrStore } = useStores();

  const [search, setSearch] = useState("");
  const formik = useFormikContext();

  const swrKey = SWR_KEYS.getWorkType();
  const getKey = (index: number) => {
    return {
      _key: swrKey,
      page: index + 1,
      search: search ?? undefined,
    };
  }

  const { data, handleScroll, mutate, isEmpty } =
    useInfiniteScroll(
      getKey,
      api.regulatoryInfo.getWorkType,
      {
        revalidateFirstPage: false,
      },
    );

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.TYPE, child);
  };

  const getLabel = (el: IWorkType) => el.work_type;

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  return (
    <Form noValidate>
      <Grid
        container
        spacing={2.5}
        p={3}
        pb={4}
      >
        <Grid item sm={12} xs={12}>
          <Field name={FIELDS_NAME.TYPE}>
            {({ field, meta }) => (
              <FormControl
                required
                fullWidth
              >
                <Autocomplete
                  {...field}
                  getLabel={getLabel}
                  onChange={handleChange}
                  label="Технологическая операция"
                  required
                  data={data}
                  handleScroll={handleScroll}
                  search={setSearch}
                  error={meta.touched && !!meta.error}
                />

                {isEmpty && (
                  <Alert sx={{ mt: 2 }} severity="warning">
                    Справочник тех. операций не заполнен по
                    данной культуре
                  </Alert>
                )}

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography>Трактористы-машинисты</Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.DRIVERS_QUANTITY}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Количество, чел"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field
            name={FIELDS_NAME.DRIVERS_ADDITIONAL_PAYMENT_PCT}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Процент от тарифного фонда для..."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.DRIVERS_PAY_GRADE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Тарифный разряд"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.DRIVERS_PAY_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Тарифная ставка за норму, руб."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.DRIVERS_HOUR_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Часовая тарифная ставка, руб."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography>Рабочие на ручных
            работах </Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.WORKERS_QUANTITY}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Количество, чел"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field
            name={FIELDS_NAME.WORKERS_ADDITIONAL_PAYMENT_PCT}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Процент от тарифного фонда для..."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.WORKERS_PAY_GRADE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Тарифный разряд"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.WORKERS_PAY_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Тарифная ставка за норму, руб."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.WORKERS_HOUR_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Часовая тарифная ставка, руб."}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography>Норма выработки </Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field
            name={FIELDS_NAME.REFERENCE_PRODUCTION_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Эталонная норма выработки"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Field name={FIELDS_NAME.SHIFT_PRODUCTION_RATE}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Сменная норма выработки"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography>ГСМ</Typography>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Field name={FIELDS_NAME.GSM}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label={"Норма горючего, л/га"}
                  fullWidth
                  size="small"
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>

      </Grid>

      <Divider />

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Готово
        </Button>
      </Box>
    </Form>
  );
};

export default AddWorkForm;
