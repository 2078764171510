export const CULTIVAR_FIELD_VARIETY_KEYS = {
  plotName: "cultivar_field__field__location__plot__name",
  trialType: "cultivar_field__type_trial",
  varietyName: "variety__name",
  cultivarName: "variety__cultivar__name",
  nameField: "cultivar_field__field__name",
  yearTrial: "trial__year_of_testing",
  status: "trial__status",
  editing: "editing",
} as const;

export const CULTIVAR_FIELD_VARIETY_LABELS = {
  plot: "ГСУ",
  cultivar: "Культура",
  variety: "Сорт",
  nameField: "Название поля",
  typeTrial: "Тип испытания",
  yearTrial: "Год испытания",
  editing: "editing",
  status: "Статус",
};

export const EMPTY_LIST_TEXT =
  "Еще не создано ни одного сортоопыта";
export const EMPTY_LIST_TEXT_WITH_FILTERS =
  "По указанным параметрам сортоопыты не найдены";
export const ADD_TEXT = "Добавить";
