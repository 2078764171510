import React, { ReactElement, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useInfiniteScroll, useQuery, useStores } from "@/hooks";
import { IAgriculturalMachinery, IPaginatedResult } from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse, IValue } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TAgriculturalMachineryKeys } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { AgrCulMachineryColumns } from "../../models/agriculturalMachinery";
import { getFilters } from "./utils";
import useDebounce from "@/hooks/useDebounce";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";


/**
 * Компонент, представляющий таблицу сельхозтехники.
 * @returns {ReactElement}
 */
const AgriculturalMachineryTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores(); //modalStore, queryStringSidebarCollector,
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const [searchGsu, setSearchGsu] = useState("");
  const dbouncedSearchGsu = useDebounce(
    searchGsu,
    500,
  );

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(query).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<IPaginatedResult<IAgriculturalMachinery>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search,
      location__plot: query.gsu?.key ?? undefined,
      type: query.type?.key ?? undefined,
      key: SWR_KEYS.getAgriculturalMachinery(),
    },
    api.regulatoryInfo.getAgrCultMachineryList,
  );

  const getGsuKey = (index: number) => {
    return {
      key: SWR_KEYS.getPlotList(),
      page: index + 1,
      search: dbouncedSearchGsu,
    };
  };

  const fetcherGsu = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlotList(
      {
        page,
        search,
      },
    );
  };

  const {
    data: gsuData,
    handleScroll: handleGsuScroll,
  } = useInfiniteScroll(getGsuKey, fetcherGsu, {
    revalidateFirstPage: false,
  });

  const gsuList: IValue[] = gsuData.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );


  const filters = getFilters(
    gsuList,
    query.gsu,
    handleGsuScroll,
    query.type,
    setSearchGsu
  );

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.AGRICULTURAL_MACHINERY, { 
      theme: THEME_MODAL.W_555 
    });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  swrStore.addMutator(SWR_KEYS.getAgriculturalMachinery(), mutate);

  const canAddAgrMachinery = userStore.isPermission(
    PERMISSIONS.regulatory_infoAgriculturalmachinery,
    PERMISSION_CRUD.add
  )

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters filters={filters} defaultSearch={query.search} />
        {canAddAgrMachinery && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant='contained'
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<IAgriculturalMachinery, TAgriculturalMachineryKeys>
        columns={AgrCulMachineryColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={"Не найдено ни одной записи"}
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
      />
    </>
  );
};

export default observer(AgriculturalMachineryTable);
