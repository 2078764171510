import React from "react";
import { useStores } from "@/hooks";
import { observer } from "mobx-react";
import { Box, Modal, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NoSSR } from "@/components/NoSSR";
import { TVariantModal } from "@/apps/Modals/const";
import {
  AddAddress,
  AddApplicants,
  AddAuthors,
  AddCensusTaker,
  AddChemistry,
  AddCommentary,
  AddCultivarToStatement,
  AddDistribution,
  AddDocument,
  AddEntities,
  AddEntity,
  AddFact,
  AddInvestment,
  AddOBCultivar,
  AddOBWageFund,
  AddOrderPlot,
  AddOriginators,
  AddPlanPlots,
  AddRegion,
  AddRepresentatives,
  Address,
  AddStatement,
  AddVariety,
  AddWork,
  ChangeAvatar,
  ChangePass,
  CopyPlan,
  Country,
  CreateStatement,
  CreateVariety,
  Cultivar,
  DeleteTrial,
  Disease,
  EditCultivarVarietyStatement,
  Employee,
  Error,
  GoToHandbook,
  LightZone,
  OkOrNotDelete,
  Order,
  Organization,
  Post,
  Quantity,
  RemarkHandbook,
  ReworkModal,
  SelectBranches,
  SelectCensusTaker,
  SelectCultivar,
  SelectCultivars,
  SelectDiseases,
  SelectMethodogyIndicator,
  SelectPlanningMethodologyGroups,
  SelectReferencies,
  SelectRegion,
  SelectSimilarVariety,
  SelectStandard,
  SelectTrial,
  SelectTrialPlan,
  SelectUserSign,
  SelectVarieties,
  SelectVariety,
  SignStatement,
  StatementDetail,
  SubjectHandbook,
  SuccesAcceptStatement,
  SuccesChangePass,
  SuccessApprovedOffbudget,
  SuccesSignStatement,
  SuccessOffBudget,
  SuccessRefinementStatement,
  SuccessRejectOffBudget,
  SuccessRejectStatement,
  SuccessReworkOffbudget,
  UniqueVarietyName,
  Units,
  ViewerSinglePhoto,
  SuccessAddPlan,
  EditPlanning,
  AddDuty,
  AddMethodologySign,
  AddMethodologySignVariant,
  SelectCultivarFieldVariety,
  Branch,
  SelectBookmark,
  Variety,
  VegetationStage,
  SelectVegetationCause,
  SelectObservationIndicator,
  SelectObservationDisease,
  SelectObservationWeatherResistance,
  AddALWork,
  SelectTrials,
  DownloadPlanning,
  AddTrials,
  SelectBranch,
  AddProductivity,
  AddLabRate,
  AddExpertTrial,
  AddVarieties,
  SelectMachinery,
  DownloadStatement,
  DownloadStatementStatus,
  DownloadCultivarField,
  DownloadSeedArrivalJournal,
  AddProductivityTestingExpertReview,
} from "@/apps/Modals/ModalContent";

import { Container, Controls, IconButton } from "./styles";
import AddRemarks
  from "./ModalContent/AddRemarks/AddRemarks";
import AddDuties from "./ModalContent/AddDuties/AddDuties";
import AddCost from "./ModalContent/AddCost";
import AddEffectivity from "./ModalContent/AddEffectivity";
import AddRemark from "./ModalContent/AddRemark";
import ReworkOBModal
  from "./ModalContent/ReworkOBModal/ReworkOBModal";
import SelectOBUsers from "./ModalContent/SelectOBUsers";
import AddCultivarToField
  from "@/apps/Modals/ModalContent/AddCultivarToField";
import AddSign from "./ModalContent/AddSign";
import MaterialType from "./ModalContent/AddMaterialType";
import DownloadOrder
  from "@/apps/Modals/ModalContent/DownloadOrder";
import CopyOrder
  from "@/apps/Modals/ModalContent/CopyOrder";
import Reproduction from "./ModalContent/Reproduction";
import AddIndicator from "./ModalContent/AddIndicator";
import AddIndicatorVariant
  from "./ModalContent/AddIndicatorVariant";
import AddMethodology from "./ModalContent/AddMethodology";
import SelectChemistry
  from "./ModalContent/SelectChemistry";
import Department from "./ModalContent/Departments";
import SelectRegions from "./ModalContent/SelectRegions";
import Districts from "./ModalContent/Districts";
import AgriculturalMachinery
  from "./ModalContent/AgriculturalMachinery";
import CultivarUsageType
  from "./ModalContent/CultivarUsageType";
import CultivarGroup from "./ModalContent/CultivarGroup";
import VegetationCauses
  from "./ModalContent/VegetationCauses";
import Plot from "./ModalContent/Plot";
import Chemistry from "./ModalContent/Chemistry";
import Expense from "./ModalContent/Expense";
import BudgetaryTrialCalc
  from "./ModalContent/BudgetaryTrialCalc";
import Rate from "./ModalContent/Rate";
import AddWeatherData from "./ModalContent/AddWeatherData";
import AddYearWeatherHistory
  from "./ModalContent/AddYearWeatherHistory";
import WeatherWork from "./ModalContent/AddWeatherWork";
import Agreement from "./ModalContent/Agreement";
import SelectObservationParameter from "./ModalContent/SelectObservationParameter";
import AddParameter from "./ModalContent/AddParameter";

export const modals: Record<
  TVariantModal,
  React.FC<any> | null
> = {
  ADD_REMARKS: AddRemarks,
  ADD_STATEMENT: AddStatement,
  ADD_ENTITY: AddEntity,
  CHANGE_PASS: ChangePass,
  CHANGE_AVATAR: ChangeAvatar,
  STATEMENT_DETAIL: StatementDetail,
  APPLICANTS: AddApplicants,
  REPRESENTATIVES: AddRepresentatives,
  AUTHORS: AddAuthors,
  CENSUS_TAKER: AddCensusTaker,
  ADD_ENTITIES: AddEntities,
  SUCCESS_CHANGE_PASS: SuccesChangePass,
  VARIETY: AddVariety,
  OK_OR_NOT_DELETE: OkOrNotDelete,
  ORIGINATORS: AddOriginators,
  CREATE_VARIETY: CreateVariety,
  SELECT_CULTIVAR: SelectCultivar,
  SELECT_CULTIVARS: SelectCultivars,
  VIEWER_SINGLE_PHOTO: ViewerSinglePhoto,
  SELECT_TRIAL: SelectTrial,
  SELECT_TRIAL_PLAN: SelectTrialPlan,
  DUTIES: AddDuties,
  SIGN_STATEMENT: SignStatement,
  SELECT_DISEASES: SelectDiseases,
  REWORK_MODAL: ReworkModal,
  SELECT_USER_SIGN: SelectUserSign,
  UNIQUE_VARIETY_NAME: UniqueVarietyName,
  SUCCESS_SING_STATEMENT: SuccesSignStatement,
  SUCCESS_ACCEPT_STATEMENT: SuccesAcceptStatement,
  ADD_CULTIVAR_TO_STATEMENT: AddCultivarToStatement,
  COPY_PLAN: CopyPlan,
  ADD_DISTRIBUTION: AddDistribution,
  SUCCESS_REJECT_STATEMENT: SuccessRejectStatement,
  SUCCESS_REFINEMENT_STATEMENT: SuccessRefinementStatement,
  SUCCESS_APROVED_OFF_B: SuccessApprovedOffbudget,
  SUCCESS_REWORK_OFF_B: SuccessReworkOffbudget,
  SUCCESS_REJECTED_OFF_B: SuccessRejectOffBudget,
  SUCCESS_SUCCESS_OFF_B: SuccessOffBudget,
  ADD_COST: AddCost,
  ADD_OFFBUDGET_CULTIVAR: AddOBCultivar,
  ADD_ADDRESS: AddAddress,
  CREATE_STATEMENT: CreateStatement,
  SELECT_CENSUS_TAKER: SelectCensusTaker,
  SELECT_VARIETY: SelectVariety,
  ADD_FACT: AddFact,
  ADD_CHEMISTRY: AddChemistry,
  ADD_WORK: AddWork,
  ADD_OFF_BUDGET_WAGE_FUND: AddOBWageFund,
  ADD_EFFECTIVITY: AddEffectivity,
  ADD_INVESTMENT: AddInvestment,
  EDIT_CULTIVAR_VARIETY_STATEMENT:
    EditCultivarVarietyStatement,
  ADD_REMARK: AddRemark,
  REWORK_OB_MODAL: ReworkOBModal,
  SELECT_OB_USERS: SelectOBUsers,
  DELETE_TRIAL: DeleteTrial,
  GO_TO_HANDBOOK: GoToHandbook,
  ADD_CULTIVAR_TO_FIELD: AddCultivarToField,
  ADD_ORDER_PLOT: AddOrderPlot,
  ADD_SIGN: AddSign,
  ADD_DOCUMENT: AddDocument,
  SELECT_PLANNING_METHODOLOGY_GROUPS:
    SelectPlanningMethodologyGroups,
  SELECT_STANDARD: SelectStandard,
  SELECT_BRANCHES: SelectBranches,
  SELECT_BRANCH: SelectBranch,
  ADD_PLAN_PLOTS: AddPlanPlots,
  SELECT_SIMILAR_VARIETY: SelectSimilarVariety,
  SELECT_REFERENCIES: SelectReferencies,
  SELECT_VARIETIES: SelectVarieties,
  DOWNLOAD_ORDER: DownloadOrder,
  COPY_ORDER: CopyOrder,
  SELECT_METHODOLOGY_INDICATOR: SelectMethodogyIndicator,
  SELECT_REGION: SelectRegion,
  ADD_COMMENTARY: AddCommentary,
  ERROR: Error,
  SUCCESS_ADD_PLAN: SuccessAddPlan,
  EMPTY: () => null,
  EDIT_PLANNING: EditPlanning,
  SELECT_CHEMISTRY: SelectChemistry,
  SELECT_CULTIVAR_FIELD_VARIETY: SelectCultivarFieldVariety,
  SELECT_BOOKMARK: SelectBookmark,
  SELECT_REGIONS: SelectRegions,
  SELECT_VEGETATION_CAUSE: SelectVegetationCause,
  SELECT_OBSERVATION_INDICATOR: SelectObservationIndicator,
  SELECT_OBSERVATION_DISEASE: SelectObservationDisease,
  SELECT_OBSERVATION_WEATHER_RESISTANCE: SelectObservationWeatherResistance,
  ADD_AL_WORK: AddALWork,
  SELECT_TRIALS: SelectTrials,
  DOWNLOAD_PLANNING: DownloadPlanning,
  ADD_TRIALS: AddTrials,
  ADD_PRODUCTIVITY: AddProductivity,
  ADD_LAB_RATE: AddLabRate,
  ADD_EXPERT_TRIAL: AddExpertTrial,
  ADD_VARIETIES: AddVarieties,
  SELECT_MACHINERY: SelectMachinery,
  DOWNLOAD_STATEMENT: DownloadStatement,
  DOWNLOAD_STATEMENT_STATUS: DownloadStatementStatus,
  DOWNLOAD_CULTIVAR_FIELD: DownloadCultivarField,
  WEATHER_DATA: AddWeatherData,
  YEAR_WEATHER_HISTORY: AddYearWeatherHistory,
  WEATHER_WORK: WeatherWork,
  DOWNLOAD_SEED_ARRIVAL_JOURNAL: DownloadSeedArrivalJournal,
  ADD_PRODUCTIVITY_TESTING_EXPERT_REVIEW: AddProductivityTestingExpertReview,
  SELECT_OBSERVATION_PARAMETER: SelectObservationParameter,

  // !!! Справочники
  ADDRESS: Address,
  LIGHT_ZONE: LightZone,
  CULTIVAR: Cultivar,
  COUNTRY: Country,
  POST: Post,
  DISEASE: Disease,
  EMPLOYEE: Employee,
  UNITS: Units,
  QUANTITY: Quantity,
  MATERIAL_TYPE: MaterialType,
  ADD_REGION: AddRegion,
  REMARK: RemarkHandbook,
  SUBJECT: SubjectHandbook,
  ORDER: Order,
  ORGANIZATION: Organization,
  REPRODUCTIONS: Reproduction,
  ADD_INDICATOR: AddIndicator,
  ADD_PARAMETER: AddParameter,
  ADD_IND_VARIANT: AddIndicatorVariant,

  ADD_METHODOLOGY: AddMethodology,
  ADD_METH_SIGN: AddMethodologySign,
  ADD_METH_SIGN_VARIANT: AddMethodologySignVariant,
  ADD_DUTY: AddDuty,
  BRANCH: Branch,
  DEPARTMENTS: Department,
  VARIETIES: Variety,
  DISTRICTS: Districts,
  VEGETATION_STAGES: VegetationStage,
  AGRICULTURAL_MACHINERY: AgriculturalMachinery,
  CULTIVAR_USAGE_TYPE: CultivarUsageType,
  CULTIVAR_GROUP: CultivarGroup,
  VEGETATION_CAUSES: VegetationCauses,
  PLOT: Plot,
  CHEMISTRY: Chemistry,
  EXPENSE: Expense,
  BUDGETARY_TRIAL_CALC: BudgetaryTrialCalc,
  RATE: Rate,
  AGREEMENT: Agreement,
};

const BaseModal = () => {
  const { modalStore } = useStores();
  const { palette } = useTheme();
  const {
    theme,
    close,
    pop,
    modals: modalStack,
    withoutCross,
  } = modalStore;
  if (!modalStack.length) {
    return null;
  }

  const renderModal = modalStack.map((el) => {
    const CurrentModal = modals[
      el.variant
    ] as React.FC<any>;
    return (
      <Modal key={el.key} open={el.isOpen}>
        <Container
          modalTheme={el.modalProps?.theme || theme}
        >
          {withoutCross ? null : (
            <Box sx={Controls}>
              <IconButton
                aria-label="close"
                sx={{
                  color: palette.blackAndWhite.gray,
                }}
                onClick={close}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          )}

          <CurrentModal
            {...el}
            handleClose={close}
            handlePop={pop}
          />
        </Container>
      </Modal>
    );
  })[modalStack.length - 1];
  return <NoSSR>{renderModal}</NoSSR>;
};
export default observer(BaseModal);
